import * as microsoftTeams from "@microsoft/teams-js";
import { callAADLoginApi } from "../features/auth";
import {
  Configuration,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";

const AAD_AUTHORITY = "https://login.microsoftonline.com";
const FAILS_OR_TIMES_OUT = "FAILS OR TIMES OUT";
const LOGIN_CODE = {
  WEB: 0,
  TEAMS: 1,
};

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority: `${AAD_AUTHORITY}/common`,
    redirectUri: window.location.protocol + "//" + window.location.host,
    postLogoutRedirectUri:
      window.location.protocol + "//" + window.location.host + "/login",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          default:
            console.log(message);
        }
      },
    },
  },
};

export const isTeamsAppLogin = async (): Promise<boolean> => {
  if (!microsoftTeams.app.isInitialized()) {
    const isFailedTeamsInitialize = await microsoftTeams.app
      .initialize()
      .catch(() => FAILS_OR_TIMES_OUT);

    if (isFailedTeamsInitialize === FAILS_OR_TIMES_OUT) {
      return new Promise((res) => {
        res(false);
      });
    }
  }

  return new Promise((res) => {
    res(true);
  });
};

export const getTeamsToken = async () => {
  return microsoftTeams.authentication
    .getAuthToken()
    .then((teamsToken) => teamsToken)
    .catch((err) => {
      console.error(err);
      return err;
    });
};

const loginTeams = async () => {
  const teamsToken = await getTeamsToken()
    .then((token) => token)
    .catch(() => {
      return null;
    });

  if (teamsToken) {
    const response = await callAADLoginApi(
      teamsToken as string,
      LOGIN_CODE.TEAMS
    );
    return response;
  }
  return null;
};

export const validateMSToken = async (tenantId: string, hash?: string) => {
  localStorage.clear();

  if (await isTeamsAppLogin()) {
    const teamsLoginResponse = await loginTeams();
    if (teamsLoginResponse) {
      return teamsLoginResponse;
    }
  }

  const msal = new PublicClientApplication({
    ...msalConfig,
    auth: {
      ...msalConfig.auth,
      authority: `${AAD_AUTHORITY}/${tenantId}`,
    },
  });
  const redirectResponse = await msal
    .handleRedirectPromise(hash)
    .catch(() => null);

  if (redirectResponse) {
    const newToken = redirectResponse?.accessToken ?? "";
    const response = await callAADLoginApi(newToken, LOGIN_CODE.WEB);
    return {
      ...response,
      refreshToken: redirectResponse.idToken,
    };
  }

  const accessTokenRequest = {
    scopes: ["openid", "profile", "email"],
    prompt: "select_account", // 계정 선택 창을 나타내기 위한 옵션
  };

  msal
    .acquireTokenSilent(accessTokenRequest)
    .then(async function (accessTokenResponse) {
      const newToken = accessTokenResponse?.accessToken ?? "";
      const response = await callAADLoginApi(newToken, LOGIN_CODE.WEB);
      if (response) {
        return {
          ...response,
          refreshToken: accessTokenResponse.idToken,
        };
      }
    })
    .catch(async function (error) {
      console.log(error);

      if (error instanceof InteractionRequiredAuthError) {
        //
      }
      msal.acquireTokenRedirect(accessTokenRequest);
    });

  if (!redirectResponse) {
    throw new Error("MSAL account info null");
  }

  const response = await callAADLoginApi(
    "authUser.accessToken",
    LOGIN_CODE.WEB
  );
  return { ...response, state: "web" };
};
